<template>
    <div class="page">
        <!-- <div class="header page-header">
            <div class="btns">
                <el-button size="mini" @click="getData" icon="el-icon-refresh">刷新</el-button>
            </div>
        </div> -->
        <div class="search">
            <el-form :inline="true" :model="search" size="small">
                <el-form-item label="干预形式">
                    <el-select v-model="search.format" placeholder="请选择" clearable>
                        <el-option label="电话" value="tel"></el-option>
                        <el-option label="微信" value="wechat"></el-option>
                        <el-option label="短信" value="sms"></el-option>
                        <el-option label="问卷" value="vote"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="干预类型">
                    <el-select v-model="search.type" placeholder="请选择" clearable>
                        <el-option label="健康教育" value="wechat"></el-option>
                        <el-option label="用药指导" value="tel"></el-option>
                        <el-option label="运动指导" value="sms"></el-option>
                        <el-option label="心理指导" value="sms"></el-option>
                        <el-option label="生活方式指导" value="vote"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData" icon="el-icon-search">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" border stripe size="small" v-loading="loading" width="100%"
                          height="calc(100vh - 250px)"
                          :header-cell-style="{ backgroundColor: '#FAFAFA' }" :row-class-name="tableRowClassName">
                    <el-table-column type="index" label="序号" align="center"
                                     :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                                     width="55"/>
                    <el-table-column prop="name" label="姓名" width="" show-overflow-tooltip/>
                    <el-table-column prop="gender" label="性别" width="" show-overflow-tooltip/>
                    <el-table-column prop="age" label="年龄" width="" show-overflow-tooltip/>
                    <el-table-column prop="time" label="执行时间" width="" show-overflow-tooltip/>
                    <el-table-column prop="format" label="干预形式" width="" show-overflow-tooltip/>
                    <el-table-column prop="type" label="干预类型" width="" show-overflow-tooltip/>
                    <el-table-column prop="jjcd" label="紧急程度" width="" show-overflow-tooltip/>
                    <el-table-column prop="content" label="干预内容" width=""/>
                    <el-table-column prop="status" label="执行状态" width="" show-overflow-tooltip/>
                    <el-table-column prop="createdAt" label="创建时间" width="220" align="center" show-overflow-tooltip/>
                </el-table>
            </div>
        </div>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                           :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                           @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loading: false,
                search: {status: '未开始'},
                table: {
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 15
                }
            }
        },
        methods: {
            tableRowClassName({row, rowIndex}) {
                if (row.status === '超时未完成') {
                    return 'warning-row';
                } else if (rowIndex === 3) {
                    return 'success-row';
                }
                return '';
            },
            dateFormatter(v) {
                return new Date(v.project.startTime).format('yyyy-MM-dd')
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            async getData() {
                this.loading = true
                const resp = await this.$http.get(`/doctor/ganyu?page=${this.table.page}&pageSize=${this.table.pageSize}&status=1`, {params: this.search})
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                }
                this.loading = false
            }
        },
        mounted() {
            // this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }

    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 0 !important;
            margin-bottom: 5px !important;
        }
    }


    .pager {
        padding-top: 10px;
        text-align: center;
    }

    ::v-deep .el-table .warning-row {
        background: rgb(250, 176, 169);
    }

    .el-table .success-row {
        background: #f0f9eb;
    }
</style>
