<template>
    <div>
        <!-- <div class="header page-header">
            <div class="btns">
                <el-button size="mini" @click="getData" icon="el-icon-refresh">刷新</el-button>
            </div>
        </div> -->
        <div class="search">
            <el-form :inline="true" :model="search" size="small">
                <el-form-item label="名称">
                    <el-input v-model="search.name" placeholder="请输入名称" clearable/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData" icon="el-icon-search">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" border stripe size="small" v-loading="loading" width="100%"
                          height="calc(100vh - 250px)" :header-cell-style="{ backgroundColor: '#FAFAFA' }">
                    <el-table-column type="index" label="序号" align="center"
                                     :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                                     width="55"/>
                    <el-table-column prop="name" label="名称" show-overflow-tooltip/>
                    <el-table-column prop="gender" label="性别" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div>
                                <span v-if="scope.row.sex=='2'"> 女 </span>
                                <span v-if="scope.row.sex=='1'"> 男 </span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="age" label="年龄"  show-overflow-tooltip/>
                    <el-table-column prop="mobile" label="电话"  show-overflow-tooltip/>
                    <el-table-column prop="idCard" label="身份证"  show-overflow-tooltip/>
                    <el-table-column prop="companyName" label="工作单位" width="" show-overflow-tooltip/>
                    <el-table-column prop="createdTime" label="创建时间" width="220" align="center" show-overflow-tooltip/>
                    <el-table-column align="center" width="120" label="操作" fixed="right">
                        <template slot-scope="scope">
                            <el-button type="warning" size="mini" @click="$router.push({path: `/main/phr/detail/${scope.row.id}`})">
                                健康档案
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                           :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                           @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loading: false,
                search: {status: ''},
                table: {
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 15
                }
            }
        },
        methods: {
            dateFormatter(v) {
                return new Date(v.project.startTime).format('yyyy-MM-dd')
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            async getData() {
                this.loading = true
                this.search.page = this.table.page
                this.search.pageSize = this.table.pageSize
                const resp = await this.$http.post(`/phr`, this.search)
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                }
                this.loading = false
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }

    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 0 !important;
            margin-bottom: 5px !important;
        }
    }



    .pager {
        padding-top: 10px;
        text-align: center;
    }
</style>
