<template>
    <div class="page">
        <el-row class="page-main">
            <div>
                <el-col :span="4" style="border: 1px solid #E4E7ED;">
                    <div style="padding: 10px">
                        <el-input size="mini" placeholder="请输入内容" prefix-icon="el-icon-search" @change="getData"
                            v-model="search">
                        </el-input>
                        <div style="display: flex; padding: 10px 20px;font-size: 13px;justify-content: space-between">
                            <div style="cursor: pointer">
                                <i class="el-icon-phone-outline"></i> 联系
                            </div>
                            <div style="cursor: pointer">
                                <i class="el-icon-user"></i> 全部客户
                            </div>
                        </div>
                    </div>
                    <div style="height: calc(100vh - 240px); overflow-y: auto;">
                        <div v-for="(items, index) in userList" :key="index" :class="{ 'selected': form === items }"
                            @click="selectItem(items)" class="text-col" :style="index==0?'border-top: 1px solid #E4E7ED;':''">
                            <div style="display: flex">
                                <el-avatar size="small" :src="avatar"></el-avatar>
                                <div class="text-right">
                                    <div class="text-flex">
                                        <span class="text-span">{{ items.name }}</span>
                                        <span class="text-span" v-if="items.sex=='2'"> 女 </span>
                                        <span class="text-span" v-if="items.sex=='1'"> 男 </span>
                                        <span class="text-span">{{ items.age }}岁</span>
                                    </div>
                                    <div style="padding-left: 5px">
                                        {{ items.companyName }}
                                    </div>
                                </div>
                            </div>
                            <el-badge :value="1" class="item" style="float: right">
                            </el-badge>
                        </div>
                    </div>
                </el-col>
                <el-col :span="10">
                    <JwChat-index width="100%" height="calc(100vh - 155px)" ref="jwChat" v-model="inputMsg"
                        :taleList="taleList" :scrollType="scrollType" :toolConfig="tool" :placeholder="placeholder"
                        :config="config" :showRightBox="false" @enter="bindEnter" @clickTalk="talkEvent"></JwChat-index>
                </el-col>
                <el-col :span="10" style="border-bottom: 1px solid #E4E7ED;border-right: 1px solid #E4E7ED;">
                    <div>
                        <div  style="padding-left:20px;border-bottom: 1px solid #E4E7ED;border-top: 1px solid #E4E7ED;">
                            <div style="float: right;padding-top: 10px;padding-right: 10px">  <el-button type="danger"
                                    size="small" @click="handelJk">健康档案</el-button></div>
                            <div class="text-flex" style="height: 54px;">
                                <span class="text-span">{{ form.name || "" }}</span>
                                <span class="text-tag">{{ form.gender || "" }}</span>
                                <span class="text-tag" v-if="form.age">{{ form.age }} 岁</span>
                            </div>
                        </div>
                        <div style="height: 80px;padding-left:20px;">
                            <div style="font-size: 14px;padding: 10px 0;"><i class="el-icon-info"></i> 添加标签</div>
                            <div v-if="form.id">
                                <el-input
                                        class="input-new-tag"
                                        v-if="inputVisible"
                                        v-model="inputValue"
                                        ref="saveTagInput"
                                        size="mini"
                                        @keyup.enter.native="handleInputConfirm"
                                        @blur="handleInputConfirm"
                                >
                                </el-input>
                                <el-button v-else size="mini" @click="showInput">+ 添加标签</el-button>
                                <el-tag :key="tag" v-for="tag in form.KHBQ" size="mini" style="margin: 5px" >
                                    {{tag}}
                                </el-tag>
                            </div>

                        </div>
                        <div style="position: relative;">
                            <userForm :form="form" height="344px"></userForm>
                        </div>
                    </div>
                </el-col>
            </div>
        </el-row>
    </div>
</template>

<script>
import avatar from "../../../assets/user.jpg"
import userForm from '../../../components/user/userInfo'

export default {
    components: {
        userForm
    },
    data() {
        return {
            inputVisible: false,
            inputValue: '',
            search: "",
            loading: false,
            activeName: "1",
            activeValue: "1",
            avatar: avatar,
            scrollType: 'noroll',
            placeholder: "请输入消息...",
            inputMsg: '',
            taleList: [],
            tool: {
                show: ['file', 'img'],
                showEmoji: false,
                callback: this.toolEvent
            },
            quickList: [],
            config: {
                name: "",
                dept: "",
            },
            userList: [],
            familyHistory: {
                "fater": {"value": [], "remark": ""}, "monter": {"value": [], "remark": ""},
                "brothers": {"value": [], "remark": ""}, "children": {"value": [], "remark": ""},
                "familyDiseases": {"value": [], "remark": ""}
            },
            pastHistory: {
                "trauma": {"has": null, "list": []},
                "illness": {"has": null, "list": []},
                "surgery": {"has": null, "list": []},
                "transfusion": {"has": null, "list": []}
            },
            other: {
                "all": "", "frist": "", "expose": [], "heredity": 0,
                "disability": {"value": [], "remark": ""}, "heredityDetail": "",
                "illnessHostory": "", "drugIrritability": {"value": [], "remark": ""},
                "foodsIrritability": ""
            },
            environment: {"water": "", "energy": "", "toilet": "", "acorral": "", "exhaust": ""},
            lifeInfo: {"daily": "", "drink": "", "foods": "", "sleep": "", "smoke": "", "style": ""},
            healthInfo: {"BMI": "", "blood": "", "waist": "", "height": "", "weight": ""},
            form: {
            }
        }
    },
    methods: {
        showInput() {
            this.inputVisible = true;
            this.$nextTick(_ => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },
        handelJk(){
            this.$router.push({path: `/main/phr/detail/${this.form.id}`})
        },
        async handleInputConfirm() {
            let inputValue = this.inputValue;
            if (inputValue) {
                this.form.KHBQ.push(inputValue);
                try {
                    let url = `/phr/tag`
                    var dataForm = {
                        idList : [this.form.id],
                        KHBQ:this.form.KHBQ
                    }
                    const resp = await this.$http.post(url, dataForm)
                    this.$message[resp.data.status](resp.data.msg)
                } catch (e) {
                    this.$message.error(e.message)
                }
            }
            this.inputVisible = false;
            this.inputValue = '';

        },
        async bindLoadHistory() {
            const history = new Array(3).fill().map((i, j) => {
                return {
                    date: "2020/05/20 23:19:07",
                    text: {text: j + new Date()},
                    mine: false,
                    name: "JwChat",
                    img: "image/three.jpeg",
                };
            });
            let list = history.concat(this.taleList);
            this.taleList = list;
            console.log("加载历史", list, history);
            //  加载完成后通知组件关闭加载动画
            this.config.historyConfig.tip = "加载完成";
            this.$nextTick(() => {
                this.$refs.jwChat.finishPullDown();
            });
        },
        bindEnter(e) {
            console.log(e)
            const msg = this.inputMsg
            if (!msg) return;
            const msgObj = {
                "date": "2024/06/12 20:19:07",
                "text": { "text": msg },
                "mine": true,
                "name": "我",
                "img": ""
            }
            this.taleList.push(msgObj)
        },
        toolEvent(type, obj) {
            console.log('tools', type, obj)
        },
        talkEvent(play) {
            console.log(play)
        },
        async getData() {
            const resp = await this.$http.post(`/phr`,{name:this.search,pageSize:2})
            if (!resp.data) {
                this.$message.error(resp.data.msg)
            } else {
                this.userList = resp.data.data
            }
        },
        async selectItem(item) {
            this.config = {
                name: item.name,
                dept: item.company,
            }
            try {
                const resp = await this.$http.get(`/phr/view/${item.id}`)
                if (resp.data) {
                    if (!resp.data.data.familyHistory) {
                        resp.data.data.familyHistory = this.familyHistory
                    }
                    if (!resp.data.data.pastHistory) {
                        resp.data.data.pastHistory = this.pastHistory
                    }
                    if (!resp.data.data.other) {
                        resp.data.data.other = this.other
                    }
                    if (!resp.data.data.environment) {
                        resp.data.data.environment = this.environment
                    }
                    if (!resp.data.data.lifeInfo) {
                        resp.data.data.lifeInfo = this.lifeInfo
                    }
                    if (!resp.data.data.healthInfo) {
                        resp.data.data.healthInfo = this.healthInfo
                    }
                    this.form = resp.data.data
                }
            } catch (e) {
                this.$message.error(e.message)
            }
            const img = ''
            let msg = ['你好, 今年体检已经完事了，报告里有些内容不太懂，而且最近每天醒来浑身酸痛起不来床，就像偶尔运动量过大或干一天体力活后睡醒了浑身酸痛疲倦难受。这样情况我该怎么办？',
                '你好，我想咨询一下职业体检预约的问题。',
                '你好, 我今天做完的体检，大概多久能出报告？']
            const list = [
                {
                    "date": "2024/06/12 21:19:07",
                    "text": { "text": msg[Math.floor(Math.random() * msg.length)] },
                    "mine": false,
                    "name": item.name,
                    "img": img,
                },
                {
                    "date": "2024/06/12 21:19:07",
                    "text": { "text": "你好" },
                    "mine": true,
                    "name": "我",
                    "img": img

                },
            ]
            this.taleList = list
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.page {
    height: 100%;
    display: flex;
    flex-direction: column;

    .page-main {
        flex: 1;
    }

    .text-col {
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #E4E7ED;
        /*border-top: 1px solid #E4E7ED;*/
        padding: 5px 10px 5px;
        cursor: pointer;
        /*height: 55px;*/
    }

    .text-flex {
        display: flex;
        align-items: center;
    }

    .text-right {
        padding-left: 10px;
        font-size: 12px;

    }

    .text-span {
        /*font-weight: 700;*/
        font-size: 13px;
        padding: 0 5px;
        color: darkslategrey;
    }

    ::v-deep .ChatPage .ChatPage-main .header {
        box-sizing: border-box;
        background-color: #fff;
        height: 55px;
        color: black;
        border-bottom: 1px solid #E4E7ED;
    }

    ::v-deep .ChatPage {
        box-shadow: none;
        border: 1px solid #E4E7ED;
    }

    .text-tag {
        padding-left: 10px;
        font-size: 13px;
    }

    .selected {
        background-color: #f5f7fa;
    }
}
</style>
