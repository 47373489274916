<template>
    <div class="page">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="线上问诊" name="1">
                <zaiXian v-if="activeName=='1'"></zaiXian>
            </el-tab-pane>
            <el-tab-pane label="干预任务" name="2">
                <ganYu v-if="activeName=='2'"></ganYu>
            </el-tab-pane>
            <el-tab-pane label="危机值监测" name="3">
                <weiJi v-if="activeName=='3'"></weiJi>
            </el-tab-pane>
            <el-tab-pane label="事件通知" name="4">
                <tong-zhi v-if="activeName=='4'"></tong-zhi>
            </el-tab-pane>
            <el-tab-pane label="我的客户" name="5">
                <ke-hu v-if="activeName=='5'"></ke-hu>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import zaiXian from "./widgets/zaixian";
    import ganYu from "./widgets/ganyu";
    import weiJi from "./widgets/weiji";
    import tongZhi from "./widgets/tongzhi";
    import keHu from "./widgets/kehu";
    export default {
        components: {
            zaiXian,
            ganYu,
            weiJi,
            tongZhi,
            keHu
        },
        data() {
            return {
                activeName: "1",
            }
        },
        methods: {
            handleClick() {
            },

        },
        mounted() {
        }
    }
</script>

<style lang="scss" scoped>
    /*.page {*/
    /*    height: 100%;*/
    /*    display: flex;*/
    /*    flex-direction: column;*/
    /*}*/

    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }
    ::v-deep .el-tabs--border-card {
        box-shadow: inherit;
    }

    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 0 !important;
            margin-bottom: 0 !important;
        }
    }

    .main-content {
        flex: 1;
        position: relative;
        padding-top: 20px;
    }

    .table-content {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .pager {
        padding-top: 10px;
        text-align: center;
    }
</style>

