<template>
    <div class="page">
        <!-- <div class="header page-header">
            <div class="btns">
                <el-button size="mini" @click="getData" icon="el-icon-refresh">刷新</el-button>
            </div>
        </div> -->
        <div class="search">
            <el-form :inline="true" :model="search" size="small">
                <el-form-item label="检查项">
                    <el-input v-model="search.name" placeholder="请输入" clearable/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData" icon="el-icon-search">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" border stripe size="small" v-loading="loading" width="100%"
                          height="calc(100vh - 250px)" :header-cell-style="{ backgroundColor: '#FAFAFA' }">
                <el-table-column type="index" label="序号" align="center"
                                 :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                                 width="55"/>
                <el-table-column prop="name" label="姓名" width="120" show-overflow-tooltip/>
                <el-table-column prop="gender" label="性别" width="90" show-overflow-tooltip/>
                <el-table-column prop="age" label="年龄" width="90" show-overflow-tooltip/>
                <el-table-column prop="phone" label="联系方式" width="120" show-overflow-tooltip/>
                <el-table-column prop="value" label="检查项" width="" show-overflow-tooltip/>
                <el-table-column prop="wjzfw" label="危机值范围" width="" show-overflow-tooltip/>
                <el-table-column prop="createdAt" label="检测时间" width="220" align="center" show-overflow-tooltip/>
                <el-table-column align="center" width="120" label="操作" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="warning" size="mini" @click="$router.push({path: `/main/phr/detail/1`})">查看详情
                        </el-button>
                    </template>
                </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                           :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                           @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loading: false,
                search: {status: '未开始'},
                table: {
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 15
                }
            }
        },
        methods: {
            dateFormatter(v) {
                return new Date(v.project.startTime).format('yyyy-MM-dd')
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            async getData() {
                this.loading = true
                const resp = await this.$http.get(`/doctor/weiji?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                }
                this.loading = false
            }
        },
        mounted() {
            // this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }

    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 0 !important;
            margin-bottom: 5px !important;
        }
    }

    /*.main-content {*/
    /*    flex: 1;*/
    /*    position: relative;*/
    /*}*/

    /*.table-content {*/
    /*    position: absolute;*/
    /*    width: 100%;*/
    /*    height: 100%;*/
    /*}*/

    .pager {
        padding-top: 10px;
        text-align: center;
    }
</style>
